import { Fragment, useEffect, useState } from "react";
import { Container } from "../../components/Container";
import {
  ContentContainer,
  Button,
  Paragraph,
  Table,
  TableConatner,
  TBody,
  THead,
  Title,
  AlertContainer,
  Alert,
} from "../../globalStyles";
import { INVOICE_STATUS } from "../../utils/constans";
import { HTTP_VERBS, requestHttp } from "../../utils/httpClient";
import { useQueryParam } from "../../utils/useQueryParams";
import { handlePayment } from "../../utils/webCheckout";

const STATUS = {
  PENDING: 0,
  OK: 1,
  FAILED: 2,
};

export const Home = () => {
  const [invoice, setInvoice] = useState(null);
  const [status, setStatus] = useState(STATUS.PENDING);
  const [key, setKey] = useQueryParam("key");

  useEffect(() => {
    if (key) {
      getInvoiceData();
    } else {
      setStatus(STATUS.FAILED);
    }
  }, []);

  const getInvoiceData = async () => {
    try {
      const response = await requestHttp({
        method: HTTP_VERBS.POST,
        endpoint: "/billings/public/invoice",
        data: {
          key: key,
        },
      });
      console.log("response", response);
      setInvoice(response.data.invoice);
      setStatus(STATUS.OK);
    } catch (error) {
      setStatus(STATUS.FAILED);
      console.log("error", error);
    }
  };

  const openPaymentProcess = (event) => {
    handlePayment(invoice.public_key, invoice.currency, invoice.total_neto);
  };

  if (status === STATUS.PENDING) {
    return (
      <Container>
        <ContentContainer>
          <Paragraph>Obteniendo datos del cobro</Paragraph>
        </ContentContainer>
      </Container>
    );
  }

  if (status === STATUS.FAILED) {
    return (
      <Container>
        <ContentContainer>
          <Paragraph>Los datos del cobro no se encuentra disponible</Paragraph>
        </ContentContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ContentContainer>
        <Title>Resumen de cobro</Title>
        <Paragraph>
          Este es el detalle del cobro de los servicios de Tapptus para el
          <br />
          periodo {invoice?.period}
        </Paragraph>
        <AlertContainer>
          <Alert color={INVOICE_STATUS[invoice?.status].color}>
            <p>{INVOICE_STATUS[invoice?.status].name}</p>
          </Alert>
        </AlertContainer>
        <TableConatner>
          <Table>
            <THead>
              <tr>
                <th>Descripción</th>
                <th>Valor</th>
              </tr>
            </THead>
            <TBody>
              <tr>
                <td>
                  {invoice?.total_users_month} usuarios x {invoice?.currency}{" "}
                  {invoice?.price_per_user_str}
                </td>
                <td>{invoice?.total_invoice_str}</td>
              </tr>
            </TBody>
          </Table>
        </TableConatner>
        <br />
        { (invoice?.status === 0 || invoice?.status === 2) && (
            <Fragment>
              <Paragraph>
                <small>
                  A continuación serás redirigido a la pasarela de pagos en
                  línea
                </small>
              </Paragraph>
              <Button onClick={openPaymentProcess}>Pagar en línea</Button>
            </Fragment>
          )}
      </ContentContainer>
    </Container>
  );
};
