import { useEffect, useState } from "react";
import { HTTP_VERBS, requestHttp } from "../../utils/httpClient";
import { useQueryParam } from "./../../utils/useQueryParams";
import { Container } from "../../components/Container";
import {
  ContentContainer,
  Paragraph,
  Table,
  TableConatner,
  TBody,
  Title,
} from "../../globalStyles";

const STATUS = {
  PENDING: 0,
  OK: 1,
  FAILED: 2,
};

export const ResponsePayment = () => {
  let [id, setId] = useQueryParam("id");
  const [status, setStatus] = useState(STATUS.PENDING);
  const [payment, setPayment] = useState(null);

  useEffect(() => {
    if (id) {
      getPaymentStatus();
    } else {
      setStatus(STATUS.FAILED);
    }
  }, []);

  const getPaymentStatus = async () => {
    try {
      const response = await requestHttp({
        api: process.env.REACT_APP_API_WOMPI,
        method: HTTP_VERBS.GET,
        endpoint: `/transactions/${id}`,
      });
      setPayment(response.data.data);
      console.log("response", response);
      setStatus(STATUS.OK);
    } catch (error) {
      console.log("error", error);
      setStatus(STATUS.FAILED);
    }
  };

  if (status === STATUS.PENDING) {
    return (
      <Container>
        <ContentContainer>
          <Paragraph>Obteniendo datos del pago</Paragraph>
        </ContentContainer>
      </Container>
    );
  }

  if (status === STATUS.FAILED) {
    return (
      <Container>
        <ContentContainer>
          <Paragraph>Ocurrio un error obteniendo datos del pago</Paragraph>
        </ContentContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ContentContainer>
        <Title>Resumen de pago</Title>
        <Paragraph>Detalle de la información de la transacción</Paragraph>
        <TableConatner>
          <Table>
            <TBody>
              <tr>
                <td>
                  <strong>Referencia del Pago</strong>
                </td>
                <td>{payment?.reference}</td>
              </tr>
              <tr>
                <td>
                  <strong>Monto</strong>
                </td>
                <td>
                  {payment?.currency} {payment?.amount_in_cents / 100}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Estado</strong>
                </td>
                <td>{payment?.status}</td>
              </tr>
            </TBody>
          </Table>
        </TableConatner>
      </ContentContainer>
    </Container>
  );
};
