import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    *, ::after, ::before {
        box-sizing: border-box;
    }
    body {
        margin: 0;
        padding: 0;
        font-family: "Poppins";
    }
`;

export const ContentContainer = styled.section`
    text-align: center;
`;

export const Title = styled.h2`
    color: #1641b5;
    position: relative;
    margin-bottom: 25px;
    z-index: 2;
    font-size: 2.4em;
    letter-spacing: -0.01em;
    font-weight: 500;
`;

export const Paragraph = styled.p`
    color: #313c56;
`;

export const TableConatner = styled.div`
    display: flex;
    justify-content: center;
`;

export const Table = styled.table`
    width: 80%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
`;

export const THead = styled.thead`
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    th {
        border-color: #ececec;
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
        border-top: 1px solid #dee2e6;
        padding: 0.75rem;
        text-align: center;
    }
`

export const TBody = styled.tbody`
    td {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #dee2e6;   
        text-align: center; 
    }
`;

export const Button = styled.button`
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    line-height: 46px;
    font-weight: 600;
    padding: 0 34px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    min-width: 160px;
    max-width: 100%;
    border: none;
    box-sizing: border-box;
    transition: all 0.5s;
    position: relative;
    z-index: 2;
    -ms-border-radius: 23px;
    border-radius: 23px;
    background-image: -webkit-linear-gradient(left, #2b56f5 0%, #46bdf4 100%);
    background-image: linear-gradient(to right, #2b56f5 0%, #46bdf4 100%);
    cursor: pointer;
`;

export const AlertContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 0px;
`;

export const Alert = styled.div`
    background-color: ${props => props.color || "#DC3545"};
    opacity: 1;
    width: 50%;
    padding: 10px 15px;
    border-radius: 4px;
    p {
        color: #fff;
        font-size: 0.99em;
        font-weight: 500;
        margin: 0;
    }
`;