export const INVOICE_STATUS = {
  0: {
    name: "Pendiente",
    color: "#187990",
  },
  1: {
    name: "Pagada",
    color: "#1D8754",
  },
  2: {
    name: "No Pagada",
    color: "#DC3545",
  },
  3: {
    name: "Cancelada",
    color: "#495058",
  },
};
