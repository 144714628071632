import {
    BrowserRouter,
    Route,
    Routes
} from 'react-router-dom';

import { Home } from './pages/Home';
import { NotFound } from './pages/NotFound';
import { ResponsePayment } from './pages/ResponsePayment';

export const Navigation = () => (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='response' element={<ResponsePayment />} />
            <Route path='*' element={<NotFound />} />
        </Routes>
    </BrowserRouter>
)