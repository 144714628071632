import React from "react";
import * as JSURL from "jsurl";
import { useSearchParams } from "react-router-dom";

export const useQueryParam = (key) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let paramValue = searchParams.get(key);

  console.log('paramValue', paramValue);

  let value = React.useMemo(() => paramValue, [paramValue]);

  let setValue = React.useCallback(
    (newValue, options) => {
      let newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(key, JSURL.stringify(newValue));
      setSearchParams(newSearchParams, options);
    },
    [key, searchParams, setSearchParams]
  );

  return [value, setValue];
};
