import { HeaderWrapper } from "./styles";

export const Header = () => (
  <HeaderWrapper>
    <img
      width={"60px"}
      alt="Logo"
      src="https://tapptus.com/static/logo/logo-mini.png"
    />
  </HeaderWrapper>
);
