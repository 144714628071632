import { Fragment } from "react";
import { GlobalStyle } from "./globalStyles";
import { Navigation } from "./Navigation";

export const App = () => (
  <Fragment>
    <GlobalStyle />
    <Navigation />
  </Fragment>
);
