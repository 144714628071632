import { Header } from "../Header";
import { ContainerWrapper } from "./styles";

export const Container = (props) => {
  return (
    <ContainerWrapper>
      <Header />
      {
          props.children
      }
    </ContainerWrapper>
  );
};
